const bootLines = [
    ">>> Initializing CV system...","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    ">>> Starting initialization...",
    ">>> Loading modules...",
    ">>> Loading core modules...",
    ">>> Loading system resources...",
    ">>> Loading system libraries...",
    ">>> Loading scripts...",
    ">>> Initializing components...",
    ">>> Initializing system modules...",
    ">>> Initializing data modules...",
    ">>> Initializing resources...",
    ">>> Initializing environment...",
    ">>> Setting up environment...",
    ">>> Setting up system environment...",
    ">>> Setting up components...",
    ">>> Preparing modules...",
    ">>> Preparing data structures...",
    ">>> Connecting to server...",
    ">>> Connecting to database...",
    ">>> Establishing connection...",
    ">>> Establishing session...",
    ">>> Initializing network connection...",
    ">>> Loading network configurations...",
    ">>> Fetching personal data...",
    ">>> Fetching resources...",
    ">>> Fetching user profile...",
    ">>> Fetching records...",
    ">>> Retrieving records...",
    ">>> Retrieving user profile...",
    ">>> Loading user environment...",
    ">>> Loading configuration files...",
    ">>> Decrypting credentials...",
    ">>> Decrypting files...",
    ">>> Processing request...",
    ">>> Starting data retrieval...",
    ">>> Starting data processing...",
    ">>> Verifying data integrity...",
    ">>> Verifying user profile...",
    ">>> Loading user interface...",
    ">>> Setting up user interface...",
    ">>> Preparing interface...",
    ">>> Preparing user interface...",
    ">>> Loading interface components...",
    ">>> Initializing user interface...",
    ">>> Starting user session...",
    ">>> Initializing user session...",
    ">>> Authenticating user...",
    ">>> Authenticating credentials...",
    ">>> Authenticating user session...",
    ">>> Loading profile...",
    ">>> Loading settings...",
    ">>> Loading user profile...",
    ">>> Loading user environment...",
    ">>> Preparing user environment...",
    ">>> Preparing user profile...",
    ">>> Starting services...",
    ">>> Charging the neural network...",
    ">>> Compiling portfolio...",
    ">>> Compiling user data...",
    ">>> Checking system status...",
    ">>> Checking for updates...",
    ">>> Calibrating skills matrix...",
    ">>> Activating experience database...",
    ">>> Starting education engine...",
    ">>> Loading assets...",
    ">>> Loading libraries...",
    ">>> Loading configurations...",
    ">>> Initializing data structures...",
    ">>> Starting initialization...",
    ">>> Connecting to server...",
    ">>> Boot process complete. Welcome!",
    ">>> Boot complete. Welcome!",
    ">>> Almost there... just one more thing...",
    ">>> Preparing documents...",
    ">>> Loading personal information...",
    ">>> Loading experience history...",
    ">>> Initializing references...",
    ">>> Initializing publications...",
    ">>> Initializing volunteer work...",
    ">>> Preparing experience history...",
    ">>> Preparing technical skills...",
    ">>> Preparing references...",
    ">>> Synchronizing projects...",
    ">>> Finalizing initialization...",
    ">>> Completing system initialization...",
    ">>> Completing final checks...",
    ">>> System ready...",
    ">>> Profile ready...",
    ">>> User interface ready...",
    ">>> Experience history ready...",
    ">>> Work experience ready...",
    ">>> Contact information ready...",
    ">>> Additional skills ready...",
    ">>> Technical skills ready...",
    ">>> Soft skills ready...",
    ">>> Achievements ready...",
    ">>> Certifications ready...",
    ">>> Languages ready...",
    ">>> Volunteer work ready...",
    ">>> Summary ready...",
    ">>> All systems operational...",
    ">>> Ready for user interaction...",
    ">>> Loading complete...",
    ">>> Initialization complete...",
    ">>> Setup complete...",
    ">>> Brewing coffee...",
    ">>> Environment ready...",
    ">>> Profile fully loaded...",
    ">>>","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    ">>> System boot up complete","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","","",
    ">>> Welcome to Richard's CV"
  ];  

export default bootLines;