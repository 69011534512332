import './BootUpSequence.css';
import React, { useState, useEffect, useRef } from 'react';
import bootLines from './bootLines';

function BootUpSequence() {
  const [lines, setLines] = useState([]);
  const [isVisible, setIsVisible] = useState(true);
  const terminalRef = useRef(null);

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      if (index < bootLines.length) {
        setLines(prevLines => [...prevLines, bootLines[index]]);
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, 25);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (terminalRef.current) {
      terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
    }
  }, [lines]);

  useEffect(() => {
    if (lines.length === bootLines.length) {
      const timeoutId = setTimeout(() => {
        setLines([]);
        setIsVisible(false);
      }, 3000); // Clear lines and hide terminal-content after 3 seconds

      return () => clearTimeout(timeoutId);
    }
  }, [lines]);

  return (
    isVisible && (
      <div id="bootUp" ref={terminalRef}>
        {lines.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
    )
  );
}

export default BootUpSequence;
