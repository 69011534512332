import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import BootUpSequence from './components/bootUpSequence/BootUpSequence';
import CV_EN from './components/cv/cv_en';
import './App.css';

function App() {
  const [isBootComplete, setIsBootComplete] = useState(false);

  useEffect(() => {
    const bootDuration = 8000;
    const timeoutId = setTimeout(() => {
      setIsBootComplete(true);
    }, bootDuration);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="terminal">
      {!isBootComplete ? (
        <BootUpSequence />
      ) : (
        <CV_EN />
      )}
    </div>
  );
}
export default App;
