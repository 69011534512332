import './cv.css';
import React, { useState, useEffect } from 'react';


const content = [
    <div id="contact" className='col' key="contact">
      <h1>Contact</h1>
      <p>Name: <span>Richard Leňo</span></p>
      <p>Date of birth: <span>31.5.2002</span></p>
      <p>Personal Email: <span>riso31502@gmail.com</span></p>
      <p>Phone Number: <span>0908 150 104</span></p>
    </div>,
    <div id="education" className='col text-wrap' key="education">
        <h1>Education</h1>
        <p>2017 - 2021 | Technical Lyceum | Technical secondary school, Humenné</p>
        <p>2022 - present | Inteligent systems | Technical university, Faculty of electronics and informatics, Košice</p>
    </div>,
    <div id="skills" className='col' key="skills">
        <h1>Knowledge</h1>
        <div id='languageSkill' className='col-12'>
            <h2>Language skills</h2>
            
            <div id='lsCategories' className='col-12 row'>
              
              <div className='col-12 col-lg-3'></div>
              
              <div className='col-12 col-lg-3 row'>
                <div className='col-12'>Understanding</div>
                <div className='col-12 row'><div className='col-6'>listening</div><div className='col-6'>reading</div></div>
              </div>
              
              <div className='col-12 col-lg-3 row'>
                <div className='col-12'>Speaking</div>
                <div className='col-12 row'><div className='col-6'>independent narration</div><div className='col-6'>spoken interaction</div></div>
              </div>
              
              <div className='col-12 col-lg-3 row'>
                <div className='col-12'>Writing</div>
                <div className='col-12'></div>
              </div>
            
            </div>

        </div>
        <div id='computerSkill' className='col-12'>
            <h2>Computer knowledge</h2>
        </div>
    </div>,
    <div id="characteristics" className='col' key="characteristics">
        <h1>Characteristics</h1>
    </div>,
    <div id="hobbies" className='col' key="hobbies">
        <h1>Hobbies</h1>
    </div>
  ];
  
  const CV_EN = () => {
    return (
      <div id='cv'>
        {content.map((item) => (
          <React.Fragment key={item.key}>
            {item}
          </React.Fragment>
        ))}
      </div>
    );
  };

export default CV_EN;
